











































































































































































































































































































































































































































































.redemption-page {
  .redemption-container {
    margin-top: 30px;
    .form-wrapper {
      max-width: 680px;
      width: 100%;
      margin: auto;
      margin-top: 30px;
    }
    .title {
      text-align: center;
    }
    .step-2 {
      margin-top: 150px;
      text-align: center;
      .success-text {
        color: #15bd15;
        margin-bottom: 30px;
      }
    }
    .form-group {
      margin-bottom: 0;
    }
    .form-control.flatpickr-input {
      background: #fff !important;
    }
  }
}
